import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, of, tap } from 'rxjs';
import { SubSink } from 'subsink';
import { environment } from '../../../../environments/environment';
import { CustomErrorResponse } from '../../models/common/custom-error-response';
import { ResourceStatus } from '../../models/common/resource-status';
import { ShelterCreateRequest } from '../../models/shelter/shelter-create-request';
import { ShelterResource } from '../../models/shelter/shelter-resource';
import { PetResource } from '../../models/pet/pet-resource';
import { ShelterUpdateRequest } from '../../models/shelter/shelter-update-request';
import { getServerErrors } from '../../utils/get-server-error';

@Injectable({
  providedIn: 'root',
})
export class PetDetailsService {
  private _resource$: BehaviorSubject<PetResource | null>;
  get resource$() {
    return this._resource$.asObservable();
  }

  private _status$: BehaviorSubject<ResourceStatus>;
  get status$() {
    return this._status$.asObservable();
  }

  private _error$: Subject<CustomErrorResponse>;
  get error$() {
    return this._error$.asObservable();
  }

  get isLoading$(): Observable<boolean> {
    return this.status$.pipe(
      map((response) => response === ResourceStatus.loading)
    );
  }

  get isComplete$(): Observable<boolean> {
    return this.status$.pipe(
      map((response) => response === ResourceStatus.complete)
    );
  }

  get isRemoved$(): Observable<boolean> {
    return this.status$.pipe(
      map((response) => response === ResourceStatus.removed)
    );
  }

  private _breed$: BehaviorSubject<any | null>;
  get breed$() {
    return this._breed$.asObservable();
  }

  private _subs: SubSink;

  constructor(private http: HttpClient) {
    this._resource$ = new BehaviorSubject<PetResource | null>(null);
    this._status$ = new BehaviorSubject<ResourceStatus>(ResourceStatus.initial);
    this._error$ = new Subject<CustomErrorResponse>();
    this._subs = new SubSink();
  }

  load(id?: string) {
    this._status$.next(ResourceStatus.loading);

    this._subs.sink = this.http
      .get<PetResource>(`${environment.endpoints.api}/pets/${id}`)
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.loaded);
          this._resource$.next(response);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
        },
      });
  }

  update(id: string, model: PetResource) {
    this._status$.next(ResourceStatus.loading);

    this._subs.sink = this.http
      .patch<PetResource>(
        `${environment.endpoints.api}/pets/${id}`,
        model
      )
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.complete);
          this._resource$.next(response);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
          this._error$.next({
            title: 'Pet',
            message: getServerErrors(err),
          });
        },
      });
  }

  create(model: PetResource) {
    this._status$.next(ResourceStatus.loading);

    this._subs.sink = this.http
      .post<PetResource>(`${environment.endpoints.api}/pets`, model)
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.complete);
          this._resource$.next(response);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
          this._error$.next({
            title: 'Pet',
            message: getServerErrors(err),
          });
        },
      });
  }

  remove(shelterId: string) {
    this._status$.next(ResourceStatus.loading);

    this._subs.sink = this.http
      .delete<PetResource>(
        `${environment.endpoints.api}/pets/${shelterId}`
      )
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.complete);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
          this._error$.next({
            title: 'Pet',
            message: getServerErrors(err),
          });
        },
      });
  }

  breeds(species?: string) {
    this._status$.next(ResourceStatus.loading);
    return this.http.get(`${environment.endpoints.api}/breeds?species=${species}`).pipe(
      map((response) => {
        if (!response) return null;
        return response;
      })
    );
  }

  patterns(species?: string) {
    this._status$.next(ResourceStatus.loading);
    return this.http.get(`${environment.endpoints.api}/patterns?species=${species}`).pipe(
      map((response) => {
        if (!response) return null;
        return response;
      })
    );
  }

  colours(species?: string) {
    this._status$.next(ResourceStatus.loading);
    return this.http.get(`${environment.endpoints.api}/colours?species=${species}`).pipe(
      map((response) => {
        if (!response) return null;
        return response;
      })
    );
  }

  destroy() {
    this._status$.complete();
    this._error$.complete();
    this._resource$.complete();
    this._subs.unsubscribe();
  }
}
