import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PetStatus } from '../../models/pet/pet-status';
import { StatusCountPerShelterModel } from '../../models/stats/status-count-per-shelter-model';

import { ShelterStatsModel } from '../../../core/models/shelter/shelter-stats-model';

@Injectable({
  providedIn: 'root',
})
export class PetStatsService {
  // private _getContent = new Map<string, Observable<number>>();
  private _getContent = new Map<string, Observable<ShelterStatsModel>>();

  constructor(private http: HttpClient) {}

  //, statuses: PetStatus[]
  getContent(shelter: string) {
    let params = new HttpParams();
    // statuses?.forEach((status) => {
    //   params = params.append('statuses', status);
    // });

    if (shelter) {
      params = params.append('shelter', shelter);
    }
    const cacheKey = params.toString();
    if (!this._getContent.has(cacheKey)) {
      this._getContent.set(
        cacheKey,
        this.http
          .get<ShelterStatsModel>(
            // `${environment.endpoints.api}/stats/status/count`,
            `${environment.endpoints.api}/stats/shelter/performance/summary`,
            { params: params }
          )
          .pipe(
            // map((response) => {
            //   return response
            //     .map((r) => r.count)
            //     .reduce((prev, current) => prev + current, 0);
            // }),
            shareReplay()
          )
      );
    }
    if (this._getContent.has(cacheKey)) {
      return this._getContent.get(cacheKey);
    }
    return of(null);
  }

  destroy() {
    this._getContent.clear();
  }
}
