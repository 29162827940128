import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ResourceList } from '../../models/linked-resource';
import { AddressModel } from '../../models/address/address-model';

@Injectable({
  providedIn: 'root',
})
export class ShelterRegionService {
  constructor(private http: HttpClient) {}

  list(): Observable<ResourceList<AddressModel>> {
    return this.http
      .get<ResourceList<AddressModel>>(`${environment.endpoints.api}/regions`)
      .pipe(map((response) => response as ResourceList<AddressModel>));
  }
}
