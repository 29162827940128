import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MaterialTableService } from '../../datasources/material-table.interface';
import { ResourceList } from '../../models/linked-resource';
import { PagedModel } from '../../models/paged-model';
import { ShelterResource } from '../../models/shelter/shelter-resource';

@Injectable({
  providedIn: 'any'
})
export class ShelterListService implements MaterialTableService<ShelterResource> {

  constructor(private http: HttpClient) { }

  list(filters: PagedModel): Observable<ResourceList<ShelterResource>> {
    return this.http.get<ResourceList<ShelterResource>>(`${environment.endpoints.api}/shelters`, {
      params: filters as any,
    }).pipe(map(response => response as ResourceList<ShelterResource>))
  }
}
