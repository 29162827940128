import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/account/auth.service';
import { SummaryStatisticsService } from './services/dashboard/summary-statistics.service';
import { PetListService } from './services/pet/pet-list.service';
import { PetParentListService } from './services/pet-parent/pet-parent-list.service';
import { ShelterDailyStatisticsService } from './services/shelter/shelter-daily-statistics.service';
import { ShelterDetailsService } from './services/shelter/shelter-details.service';
import { ShelterListService } from './services/shelter/shelter-list.service';
import { ShelterSummaryService } from './services/shelter/shelter-summary.service';
import { ShelterUsersService } from './services/shelter/shelter-users.service';
import { TopPerformingSheltersService } from './services/shelter/top-performing-shelters.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { BasicAuthInterceptor } from './interceptors/basic-auth.interceptor';
import { PetStatsService } from './services/stats/pet-stats.service';
import { PetStatsSummaryService } from './services/pet/pet-stats-summary.service';
import { ExportService } from './services/export/export.service';
import { PetDetailsService } from './services/pet/pet-detail.service';

import { ShelterRegionService } from './services/shelter/shelter-region.service';
import { PetBreedService } from './services/pet/pet-breed.service';

@NgModule({
  declarations: [],
  providers: [
    // Account
    AuthService,

    // Dashboard
    SummaryStatisticsService,

    // Pet
    PetListService,

    // Pet Parent
    PetParentListService,

    // Shelter
    ShelterDailyStatisticsService,
    ShelterDetailsService,
    ShelterListService,
    ShelterSummaryService,
    ShelterUsersService,
    TopPerformingSheltersService,

    ShelterRegionService,

    // Pets Stats
    PetStatsService,
    PetStatsSummaryService,

    // Pet Details
    PetDetailsService,

    // Breed ?
    PetBreedService,

    // Export
    ExportService,

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
  ],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
