import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MaterialTableDataSource } from '../../datasources/material-table.datasource';
import { MaterialTableService } from '../../datasources/material-table.interface';
import { ResourceList } from '../../models/linked-resource';
import { PagedModel } from '../../models/paged-model';
import { PetResource } from '../../models/pet/pet-resource';

@Injectable({
  providedIn: 'root',
})
export class PetListService implements MaterialTableService<PetResource> {
  constructor(private http: HttpClient) {}

  list(filters: PagedModel): Observable<ResourceList<PetResource>> {
    return this.http
      .get<ResourceList<PetResource>>(`${environment.endpoints.api}/shelters/${filters.id}/pets`, {
        params: filters as any,
      })
      .pipe(map((response) => response as ResourceList<PetResource>));
  }
}
