import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PetStatsModel } from '../../models/pet/pet-stats-model';

@Injectable({
  providedIn: 'root'
})
export class PetStatsSummaryService {
  private _getContent = new Map<string, Observable<PetStatsModel>>();

  constructor(private http: HttpClient) {}

  getContent(petId: string) {
    const cacheKey = petId.toString();
    if (!this._getContent.has(cacheKey)) {
      this._getContent.set(
        cacheKey,
        this.http
          .get<PetStatsModel>(
            `${environment.endpoints.api}/pets/${petId}/stats`,
          )
          .pipe(
            shareReplay()
          )
      );
    }
    if (this._getContent.has(cacheKey)) {
      return this._getContent.get(cacheKey);
    }
    return of(null);
  }

  destroy() {
    this._getContent.clear();
  }
}
