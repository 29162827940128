import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MaterialTableService } from '../../datasources/material-table.interface';
import { ResourceList } from '../../models/linked-resource';
import { PagedModel } from '../../models/paged-model';
import { PetParentResource } from '../../models/pet/pet-parent-resource';

@Injectable({
  providedIn: 'root'
})
export class PetParentListService implements MaterialTableService<PetParentResource> {

  constructor(private http: HttpClient) { }

  list(filters: PagedModel): Observable<ResourceList<PetParentResource>> {
    return this.http.get<ResourceList<PetParentResource>>(`${environment.endpoints.api}/petparents`, {
      params: filters as any,
    }).pipe(map(response => response as ResourceList<PetParentResource>))
  }
}
