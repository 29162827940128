import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { environment } from '../../../../environments/environment';
import { CustomErrorResponse } from '../../models/common/custom-error-response';
import { ResourceStatus } from '../../models/common/resource-status';
import { ResourceList } from '../../models/linked-resource';
import { PagedModel } from '../../models/paged-model';
import { ShelterUserCreateRequest } from '../../models/shelter/shelter-user-create-request';
import { ShelterUserResource } from '../../models/shelter/shelter-user-resource';
import { getServerErrors } from '../../utils/get-server-error';

@Injectable({
  providedIn: 'any',
})
export class ShelterUsersService {
  private _users$: BehaviorSubject<ShelterUserResource[]>;
  get users$() {
    return this._users$.asObservable();
  }

  private _status$: BehaviorSubject<ResourceStatus>;
  get status$() {
    return this._status$.asObservable();
  }

  private _error$: Subject<CustomErrorResponse>;
  get error$() {
    return this._error$.asObservable();
  }

  get isLoading$() {
    return this.status$.pipe(
      map((response) => response === ResourceStatus.loading)
    );
  }

  get isComplete$() {
    return this.status$.pipe(
      map((response) => response === ResourceStatus.complete)
    );
  }

  private _subs: SubSink;

  constructor(private http: HttpClient) {
    this._subs = new SubSink();
    this._status$ = new BehaviorSubject<ResourceStatus>(ResourceStatus.initial);
    this._users$ = new BehaviorSubject<ShelterUserResource[]>([]);
    this._error$ = new Subject<CustomErrorResponse>();
  }

  load(filter: PagedModel, shelterId?: string, reset?: boolean) {
    this._status$.next(ResourceStatus.loading);

    this._subs.sink = this.http
      .get<ResourceList<ShelterUserResource>>(
        `${environment.endpoints.api}/shelters/${shelterId}/users`,
        {
          params: filter as any,
        }
      )
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.loaded);
          if (reset) {
            this._users$.next(response.items);
          } else {
            this._users$.next([...this._users$.value, ...response.items]);
          }
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
        },
      });
  }

  createOrUpdate(model: ShelterUserCreateRequest, user?: ShelterUserResource) {
    this._status$.next(ResourceStatus.loading);
    let request = this.http
    .post<ShelterUserResource>(
      `${environment.endpoints.api}/shelterusers`,
      model
    );
    if (user) {
      request = this.http
      .patch<ShelterUserResource>(
        `${environment.endpoints.api}/shelterusers/${user.id}`,
        model
      )
    }

    this._subs.sink = request
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.complete);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
          this._error$.next({
            title: 'Shelter User',
            message: getServerErrors(err),
          });
        },
      });
  }

  remove(user: ShelterUserResource) {
    this._status$.next(ResourceStatus.loading);
    const currentUsers = [...this._users$.value];
    this._users$.next(this._users$.value.filter((u) => u.id !== user.id));

    this._subs.sink = this.http
      .delete<ShelterUserResource>(
        `${environment.endpoints.api}/shelterusers/${user.id}`
      )
      .subscribe({
        next: (response) => {
          this._status$.next(ResourceStatus.complete);
        },
        error: (err) => {
          this._status$.next(ResourceStatus.failed);
          this._users$.next(currentUsers);
          this._error$.next({
            title: 'Shelter User',
            message: getServerErrors(err),
          });
        },
      });
  }

  destroy() {
    this._status$.complete();
    this._error$.complete();
    this._users$.complete();
    this._subs.unsubscribe();
  }
}
