import { HttpErrorResponse } from '@angular/common/http';

export function getServerErrors(serverError: HttpErrorResponse) {
  if (typeof serverError.error === 'object') {
    try {
      if (serverError.error && serverError.error.errors) {
        if (Array.isArray(serverError.error.errors)) {
          return serverError.error.errors.map((e: any) => e.message).join('\n');
        }
        const errorKeys = Object.keys(serverError.error.errors);
        return errorKeys.map(key => serverError.error.errors[key].join('\n')).join('\n');
      } else if (serverError?.error?.hint) {
        const mpErrors = JSON.parse(JSON.parse(serverError?.error?.error?.replace("Invalid parameter: ", "")));
        const mpErrorObject = mpErrors.error;
        return mpErrorObject?.error_user_msg;
      } else if (serverError.error.error) {
        return serverError.error.error;
      } else if (serverError.error.detail) {
        return serverError.error.detail;
      }
      return serverError.error.title;
    } catch (e) {
      return typeof serverError.error === 'string' ? serverError.error : serverError.message;
    }
  }
  return typeof serverError.error === 'string' ? serverError.error : serverError.message;
}
