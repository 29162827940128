import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
// import { MaterialTableDataSource } from '../../datasources/material-table.datasource';
// import { MaterialTableService } from '../../datasources/material-table.interface';
import { ResourceList } from '../../models/linked-resource';
// import { PagedModel } from '../../models/paged-model';
import { BreedResource } from '../../models/breed/breed-resource';

@Injectable({
  providedIn: 'root',
})
export class PetBreedService {
  constructor(private http: HttpClient) {}

  // list(filters: PagedModel): Observable<ResourceList<BreedResource>> {
  list(filters: any): Observable<ResourceList<BreedResource>> {
    return this.http
      .get<ResourceList<BreedResource>>(`${environment.endpoints.api}/breeds`, {
        params: filters as any,
      })
      .pipe(map((response) => response as ResourceList<BreedResource>));
  }

  patterns(filters: any): Observable<any> {
    return this.http
      .get<ResourceList<BreedResource>>(`${environment.endpoints.api}/patterns`, {
        params: filters as any,
      })
      .pipe(map((response) => response));
  }

  colours(filters: any): Observable<ResourceList<BreedResource>> {
    return this.http
      .get<ResourceList<BreedResource>>(`${environment.endpoints.api}/colours`, {
        params: filters as any,
      })
      .pipe(map((response) => response as ResourceList<BreedResource>));
  }

}
