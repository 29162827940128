import { IEnvironment } from "./environment.interface";

export const environment : IEnvironment = {
  production: true,
  endpoints: {
    api: "https://shelter-app-api-dev.poweredbytechsys.co.za",
    media: "https://shelter-app-api-dev.poweredbytechsys.co.za"
  },
  storage: {
    auth: "hills.shelter.auth"
  }
}
