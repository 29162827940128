import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoginModel } from '../../models/account/login-model';
import { UserResource } from '../../models/account/user-resource';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$: BehaviorSubject<LoginModel | null>;
  get user$() {
    return this._user$.asObservable();
  }

  constructor(private http: HttpClient) {
    this._user$ = new BehaviorSubject<LoginModel | null>(null);
    this.setUser();
  }

  login(model: LoginModel) {
    const credentials = `${model.username}:${model.password}`;
    const credentialsBase64 = btoa(credentials);
    window.localStorage.setItem(environment.storage.auth, credentialsBase64);
    return this.http.get(`${environment.endpoints.api}/shelterusers`).pipe(
      tap(() => {
        window.localStorage.setItem(
          environment.storage.auth,
          credentialsBase64
        );
        this._user$.next({username: model.username, password: ''});
      })
    );
  }

  logout() {
    window.localStorage.removeItem(environment.storage.auth);
  }

  credentials(): Observable<string | null> {
    return of(window.localStorage.getItem(environment.storage.auth)).pipe(
      map((response) => {
        if (!response) {
          return null;
        }
        return response;
      })
    );
  }

  setUser() {
    if (!window.localStorage.getItem(environment.storage.auth)) {
      return;
    }

    const base64String = atob(
      window.localStorage.getItem(environment.storage.auth) ?? ''
    );
    if (!base64String) {
      return;
    }

    if (typeof base64String !== 'string') {
      return;
    }
    const [username] = base64String.split(':');
    this._user$.next({ username: username, password: '' });
  }
}
