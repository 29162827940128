import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/account/auth.service';
import { Router } from '@angular/router';
import { skipAuthHeader } from '../utils/skip-auth-header';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authService.credentials().pipe(
      switchMap((credentials: string | null) => {
        if (!skipAuthHeader(request.url)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Basic ${credentials}`,
            },
          });
        }
        return next.handle(request);
      })
    );
  }
}
