import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';
import { PagedModel } from '../../models/paged-model';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private _exportBusy$: BehaviorSubject<boolean>;
  get exportBusy$(): Observable<boolean> {
    return this._exportBusy$;
  }

  constructor(private http: HttpClient) {
    this._exportBusy$ = new BehaviorSubject<boolean>(false);
  }

  download(url: string, model: PagedModel, ext: string, method: 'GET' | 'POST' = 'GET') {
    this._exportBusy$.next(true);
    return this._getBlob(url, method, model).pipe(
      tap((response) => {
        this._handleDownload(response, model, ext);
      }),
      finalize(() => this._exportBusy$.next(false)),
    );
  }

  destroy() {
    this._exportBusy$.next(false);
  }

  private _getBlob(url: string, method: 'GET' | 'POST', model: PagedModel) {
    if (method === 'GET') {
      return this.http.get(url, { params: model as any, responseType: 'blob' });
    }
    return this.http.post(url, model, { responseType: 'blob' });
  }

  private _handleDownload(blob: Blob, model: PagedModel, ext: string) {
    const fileName = `${model.filename}${ext}`;
    if ((window.navigator as any).msSaveOrOpenBlob) {
      const fileData = [blob];
      const blobObject = new Blob(fileData);
      (window.navigator as any).msSaveOrOpenBlob(blobObject, fileName);
    } else {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      link.href = downloadUrl;

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    }
  }
}
